var Extra = Extra || {}

Extra.FilterToken = {
    render: ({ Title, Id}) => {
        return `
            <div class="filter-item">
                <span>${Title}
                    <a class="icon-close js-active-filter" href="#" data-filter="${Id}"></a>
                </span>
            </div>
        `
    }
}